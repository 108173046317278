footer {
  padding: 3rem 2rem;
  gap: 3.5rem;
  background-color: var(--footerBg);
  display: flex;
  flex-direction: column;
}
footer a {
  color: var(--footerTextColor) !important;
}
footer a:hover {
  text-decoration: none;
  cursor: pointer;
  color: var(--black) !important;
}

footer .topFooter {
  max-width: 1176px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  align-items: center;
}

footer .topFooterLinks {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 3.375rem;
  row-gap: 1rem;
}

footer .topFooterLinks > li {
  transition: transform 0.5s ease;
}

footer .topFooterLinks > li:hover {
  transform: translateY(-4px);
}

footer .copyrightFooter {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  max-width: 1176px;
  margin: auto;
}

footer .bottomFooterLinks {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

footer .bottomFooterLinks > li {
  transition: transform 0.5s ease;
}

footer .bottomFooterLinks > li:hover {
  transform: translateY(-8px);
}

footer .additionalTopLinks a > img,
footer .additionalTopLinks li {
  transition: transform 0.5s ease;
}
footer .additionalTopLinks a:hover > img,
footer .additionalTopLinks li:hover {
  transform: translateY(-8px);
}

footer .awardsContainer {
    display: flex  ;
    gap: 1.5rem;
    padding: 0 1rem;
}

footer .socialMediaLinks {
  display: flex;
  gap: 1rem;
}

footer .additionalLinks {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

footer .passengersLinkWrapper {
  display: flex;
}

footer .passengersLinkWrapper > a {
  display: flex;
  gap: 4px;
  align-items: center;
}

footer .separator {
  border: 1px solid var(--white);
  height: 1.5rem;
  width: 1px;
  opacity: 0.8;
  margin-left: 12px;
}

[lang="ar"] footer .separator {
  margin-right: 12px;
}

footer .additionalTopLinks {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

footer .copyrightFooter > p {
  color: var(--footerTextColor);
}
footer .footerawardsSec{
  position: relative;
}
footer .footerawardsSecText{
  color: var(--footerTextColor);
  font-size: 12px;
  text-align: center;
  line-height: 100%;
  font-family: var(--enRegular);
}
footer .footerawardsSecImage{
  max-height: 90px;
}
@media (min-width: 640px) and (max-width: 991px) {
  footer .topFooterLinks {
    grid-template-columns: auto auto auto;
  }
}

@media (max-width: 991px) {
  footer  .topFooterLinks li {
    text-align: center;
  }
}

@media (min-width: 992px) {
  footer {
    padding: 2.5rem 1.5rem 5rem;
    gap: 47px;
  }
  footer .footerawardsSecText{
    margin-bottom: 0.5rem;
  }
  footer .copyrightFooter {
    flex-direction: row;
    gap: auto;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  footer .topFooter {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: auto;
    width: 100%;
  }

  footer .socialMediaLinks {
    gap: 0.5rem;
  }
  footer .additionalLinks {
    gap: 1.5rem;
    margin-right: auto;
  }
  footer .additionalTopLinks {
    flex-direction: column;
    align-items: flex-start;
  }
  footer .separator {
    border: 1px solid var(--white);
    width: 104px;
    margin-top: 12px;
    opacity: 0.8;
    height: 0;
    margin-left: unset;
  }
  [lang="ar"] footer  .separator {
    margin-right: unset;
  }
  footer .passengersLinkWrapper {
    flex-direction: column;
  }
}

[lang="ar"] footer .passengersLinkWrapper > a > img {
  transform: rotate(-90deg);
}

@media(max-width:768px){
  .footerawardsSecText {
       margin-bottom: 15px;
  }
}
@media (max-width: 490px) {
  footer .topFooterLinks li {
    text-align: left;
  }
}