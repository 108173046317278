.curvePad {
    height: 0rem;
  }

@media(min-width: 992px){
  .bannerImgContainer{
    aspect-ratio: 22/10;
  }
}
  
.shareBar {
  margin: 1rem 0 1rem;
  padding: 24px 0;
  border-bottom: 1px solid var(--divider-bg);
}
.shareBar.borderTop {
  border-bottom: 0 none;
  border-top: 1px solid var(--divider-bg);
}