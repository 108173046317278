

.whoWeAre {
  background-color: var(--white);
  padding: 7rem 1.5rem;
}

.whoWeAre .innerContainer {
  max-width: 1176px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.whoWeAre .imgContainer {
  display: flex;
  gap: 5px;
}

.whoWeAre  .imgContainer > img {
  width: 168px;
  height: 216px;
  position: relative;
}

.whoWeAre  .img2 {
  top: -50px;
}

.whoWeAre .textContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.whoWeAre  .heading {
  color: var(--text-primary);
}

.whoWeAre .description {
  color: var(--black);
}

@media (max-width: 992px) {
  .whoWeAre  .description {
    text-align: center;
  }
}
@media (max-width: 490px) {
   .whoWeAre {
    padding: 64px 24px;
}

}

@media (min-width: 992px) {
   .whoWeAre {
    padding: 3rem 8.25rem;
  }
  .whoWeAre  .innerContainer {
    flex-direction: row;
    gap: 5rem;
    align-items: flex-start;
  }
  .whoWeAre   .imgContainer > img {
    width: 271px;
    height: 452px;
  }
  .whoWeAre  .textContainer {
    gap: 2.5rem;
    align-items: flex-start;
  }
}

[lang="ar"]   .whoWeAre  .imgContainer > img {
  transform: scaleX(-1);
}
.whoWeAre  .imgContainer.whoWeAreWithoutImage {
  display: none;
}
.whoWeAre .whoWeAreWithoutImage h3{
  display: flex;
  justify-content: center;
  width: 100%;
}
.whoWeAre .whoWeAreWithoutImage p{
  text-align: center;
}
.whoWeAre .whoWeAreBtnGroupContainer{
  display: flex;
  justify-content: center;
  width: 100%;
}
.whoWeAre.whoWeAreWithImage {
padding: 7rem 8.25rem 3rem;
}
@media (max-width: 490px) {
  .whoWeAreBtnGroupContainer .btnGroupcontainer {
    flex-direction: column;
    width: 100%;
    gap: 10px;
}
.whoWeAreBtnGroupContainer .btnGroupcontainer .btn-default {
  justify-content: center;
}
.whoWeAre.whoWeArePaddingTop {
  padding: 64px 24px;
}
.whoWeAre .description {
  text-align: left;
}
.whoWeAre .textContainer {

align-items: start;
}
.whoWeAre .imgContainer>img {
  width: 138px;
}
}
@media (min-width: 992px) {
  .whoWeAre .whoWeAreBtnGroupContainer {
    justify-content: start;
}
  .whoWeAre .textContainer {
gap: 2.0rem;
      transform: translateY(-56px);
  }
.whoWeAre .description {
  font-size: 1.3rem;
  line-height: 130%;
}
            .whoWeAre .imgContainer>img {
      width: 251px;
      height: 432px;
  }
}

@media (max-width: 490px){
.whoWeAre.whoWeAreWithImage {
  padding: 8rem 24px 3rem;
}
}