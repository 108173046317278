.ourCompanies {
  padding: 0.375rem 1.5rem 4rem;
  background-color: var(--section-bg);
}

.innerContainer {
  max-width: 1176px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.ourCompanies .textContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
}
.heading {
  color: var(--text-primary);
}
.title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.title .border {
  border: 2px solid var(--primary-color);
  flex-grow: 1;
}

.title > h3 {
  color: var(--primary-color);
}

.imgWrapper {
  display: grid;
  grid-template-columns: auto;
  gap: 1.5rem;
  justify-content: center;
}

@media (min-width: 640px) {
  .title {
    gap: 2rem;
  }
  .imgWrapper {
    grid-template-columns: 1fr 1fr;
    overflow: auto;
    justify-content: start;
}
}
@media(max-width:1024px){
  .imgWrapper {
    grid-template-columns: 1fr 1fr;
    overflow: auto;
    justify-content: flex-start;
}
  .companyCard {
    min-width: 380px;
}
}
.ourCompanies {
  padding: 3rem 8.25rem;
}

@media(max-width:768px){
  .ourCompanies {
    padding: 4rem 1.5rem 4rem;
  }
}

@media(max-width:490px){
  
  .imgWrapper {
    display: block; 
  }
  .companyCard {
    min-width: auto;
    margin-bottom:1.5rem;
}


}
