/* @import url("../../assets/css/variables.css");
@import url("../../assets/css/_fonts.css"); */

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  padding: 0;
  margin: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}

html[lang="ar"] body {
  direction: rtl;
}

col-12>h1,
.col-12 > h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headerFont);
  font-weight: 500;
}

[lang="ar"] h1 {
  font-weight: 700;
}

[lang="ar"] h2 {
  font-weight: 700;
}

[lang="ar"] h3 {
  font-weight: 700;
}

[lang="ar"] h4 {
  font-weight: 700;
}

[lang="ar"] h5 {
  font-weight: 700;
}

[lang="ar"] h6 {
  font-weight: 700;
}

body {
  font-family: var(--bodyTextRegular);
}

h1 {
  font-size: 1.75rem;
  line-height: 2.1rem;
}

h3 {
  font-size: 1.55rem;
  line-height: 1.8rem;
}

h5 {
  font-size: 1.375rem;
  line-height: 1.625rem;
  letter-spacing: 0em;
}

h6 {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  letter-spacing: 0em;
}

p.x-large {
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.5625rem;
  letter-spacing: 0em;
  font-family: var(--bodyTextLight);
}

p.large-reg {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3125rem;
  letter-spacing: 0em;
  font-family: var(--bodyTextLight);
}

p.medium-reg {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0em;
  font-family: var(--bodyTextLight);
}

p.micro-reg {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.975rem;
  letter-spacing: 0em;
  font-family: var(--bodyTextLight);
}

.btn-default {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  font-family: var(--bodyTextMedium);
}

.nav-bold {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: 0em;
  font-family: var(--bodyTextRegular);
  color: var(--nav-bold)
}
.navbar{
  background-color: var(--primary-color) !important;
  transition: top .2s, opacity .2s, background-color .2s, z-index .2s;
  transition-timing-function: ease-in-out;
  align-items: flex-start;
}
.navbar.scrollDn{
  top: -1rem;
  opacity: 0;
  z-index: -1;
}
.navbar.scrollUp{
  top:0;
  opacity: 1;
  z-index: 1030;
}  
.navbar-nav .nav-link{
  padding: .5rem;
  border-bottom: 1px solid var(--divider-bg);
}
.navbar-nav .nav-link:first-of-type{
  border-top: 1px solid var(--divider-bg);
}
.navbar-nav .nav-link:last-of-type{
  border-top: 0 none;
}
.navbar-nav .nav-link.dropdown-toggle.show{
  background-color: var(--focus-color);
}
.dropdown-toggle::after{
  display: none;
}
.dropdown-item{
  color: var(--primary-btn-color) !important;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: transparent;
}
.navbar-brand{
  margin-left: 0;
  margin-right: 0;
}
.navbar.reverse, .navbar.reverseBG, .bgTransparent.off.reverse, .bgTransparent.off.reverseBG{
  background-color: var(--white) !important;
  box-shadow: 0px 0px 10px 0px #0000004a;
}
.navbar.reverse svg path, .navbar.reverseBG svg path{
  fill: var(--primary-color);
}
.navbar.reverse a, .navbar.reverseBG a{
  color: var(--primary-color) !important;
}  
.navbar.reverse .navbar-nav div, .navbar.reverseBG .navbar-nav div{
  border-color: var(--primary-color);
}
.navbar-nav .nav-link, .navbar-toggler{
  outline: 0 none;
  box-shadow: none !important;
}
.navbar-toggler .navbar-toggler-icon{
  background-image: url(../../assets/images/hamburger.svg);
}
.navbar.reverse .navbar-toggler .navbar-toggler-icon, .navbar.reverseBG .navbar-toggler .navbar-toggler-icon{
  background-image: url(../../assets/images/hamburger.svg);
}
.DDHead{
  left: 1rem;
  top: 7.5rem;
}
.slim-body {
  max-width: 768px !important;
  margin: auto;
  padding: 0 1.5rem;
}
.bodyBox {
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  background-color: var(--white);
  position: relative;
}

.slick-next:before,
.slick-prev:before {
  color: black !important;
}

/*Global Classes*/
.fixedBGTop{
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 100%;
}
.bgColor{
  position: relative;
  background-color: var(--section-bg);
}
.bgColorPrimary{
  background-color: var(--primary-color);
  color: var(--white);
}
.colorSecondary{
  color: var(--secondary-color);
  text-decoration: none;
}
.sectionHead{
  color:var(--white)
}
.comTitle{
  color: var(--text-title);  
  font-size: 1rem;
  font-weight: 700;
}
.dirName{
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 110%;
}
.moreLink{
  display: inline-block;
  color: var(--primary-btn-color);
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  margin-top: .5rem;
}
.moreLink svg, .inlineImg {vertical-align: inherit;}
.bgCurve-top{
  display: block;
  position:absolute;
  width: 100%;
  height: 100%;
}
.bgCurve-top img{
  width: 100%;
  max-height: 100%;
}
.headerBG{
  position: fixed;
  background-color: var(--primary-color);
  height: 7.5rem;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1029;
}
.headerMargin{
  padding-top: 3.5rem;
}
.bgDiv{
  background-color: var(--primary-color);
}
.sliderHead{
  position: relative;
  color: var(--white);
  padding-top: 4rem;
  padding-bottom: 1rem;
}
.slick-slide .row{
  display: flex !important;
}
.slick-dots li button:before{
  color: var(--secondary-color) !important;
}
.contentList{
  list-style: disc;
  margin-left: .25rem;
}
.contentListNum{
  list-style:decimal;
  margin-left: .25rem;
}
.contentList li, .contentListNum li{
  margin-left: 1rem;
  padding-left: .25rem;
}

.slick-dots {
  text-align: left !important;
}
.arrowImg{
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.slick-dots li button:before {
  color: #94a1f2 !important;
  width: 30px !important;
  height: 30px !important;
}
.slick-slider {
  margin-top: 1.5rem;
}
.slick-dots {
  bottom: -40px !important;
}
.centered-body-sm {
  max-width: 776px;
  margin: auto;
  padding: 0 1.5rem;
}
/*AR Styling*/
html[lang="ar"] p, html[lang="ar"] h1, html[lang="ar"] h2, html[lang="ar"] h3, html[lang="ar"] h4, 
html[lang="ar"] h5, html[lang="ar"] h6, html[lang="ar"] ul, html[lang="ar"] li {
  direction: rtl;
  text-align: right;
}
html[lang="ar"] .dropdown-item{
  text-align: right !important;
}
html[lang="ar"] .bgCurve-top, html[lang="ar"] .inlineImg, html[lang="ar"] .moreLink svg{
  transform: scaleX(-1);
}
html[lang="ar"] .comTitle, html[lang="ar"] .dirName, html[lang="ar"] {
  direction: rtl;
}
html[lang="ar"] .contentList, html[lang="ar"] .contentListNum {
  margin-left: 0;
  margin-right: .25rem;  
}
html[lang="ar"] .contentList li, html[lang="ar"] .contentListNum li {
  margin-left: 0;
  padding-left: 0;
  margin-right: 1rem;
  padding-right: .25rem;
}
html[lang="ar"] .arrowImg{
  transform: scale(-1);
}
html[lang="ar"] .dropdown-toggle::after{
  margin-right: .255rem;
}
/* ////////////mobile ////// */

/*Media Quries*/
@media (min-width: 992px) {
  h1 {
    font-size: 3.5rem;
    line-height: 4.55rem;
  }
  h2 {
    font-size: 1.625rem;
    line-height: 1.9375rem;
    letter-spacing: 0em;
  }
  h3 {
    font-size: 2.5rem;
    line-height: 3.25rem;
  }
  h5 {
    font-size: 1.5rem;
    line-height: 1.9375rem;
  }
  p.x-large {
    font-size: 1.375rem;
    line-height: 160%;
  }
  li.x-large {
    font-size: 1.375rem !important;
    line-height: 160% ;
    font-family: var(--bodyTextLight);
  }
  p.large-reg {
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }
  p.medium-reg {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  p.micro-reg {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
  .btn-default {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
  .headerMargin{
    padding-top: 8.5rem !important;
  }  
  .bodyBox{
    padding: 6rem;
  }
  .navbar-nav .nav-link{
    padding: .5rem 1.375rem !important;
    border-bottom: 0 none;
  } 
  .navbar-nav .nav-link.dropdown-toggle.show{
    padding-top: 2.5rem !important;
    padding-bottom: 2rem !important;
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .navbar.reverse::before{
    position: absolute;
    top: 6rem;
    content: "";
    width: 100%;
    height: 1px;
    border-top : 1px solid var(--divider-bg);
  }
}

@media screen and (max-width: 575px){
  .headerBG{
    height:5.5rem;
  }  
  .slick-dots, [lang="ar"] .slick-dots{
    text-align: center !important;
  }
  .btnOuter {
    flex-direction: row-reverse;
  }
  .detailBox{
    box-shadow:none !important;
    border: 0 none !important;
    background-color: transparent !important;
  }
  .sliderHead{
    margin-top: 4rem;
  }
  .sliderHead h3{
    text-align: center;
  }
  .sliderArrow{
    display: none !important;
  }
  .slick-slide > div{
    position: relative;
    left: -1rem;
  }
  .slick-slide.slick-current > div{
    left: -.5rem;
  }  
}
@media screen and (min-width: 768px){    
  .bgTransparent{
    background-color: transparent !important;
  }     
  .bgTransparent.off{
    background-color: var(--primary-color) !important;
  }
  .navbar-collapse {
    align-items: flex-start;
  }
}
@media screen and (max-width: 991px){     
  .DDHead{
    top: 6rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu{
    margin: 0 0 0 0 !important;
    border: 0 none;
  }
  .dropdown-divider{
    margin: .25rem 0;
  }
}

[lang="ar"] .bodyBox{
  text-align: right;
}

.primaryBtn {
  background-color: var(--primary-btn-color);
  border-radius: 4px;
  gap: 0.5rem;
  color: var(--white);
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 1rem;
}

.primaryBtn:hover{
  background-color: var(--primary-btn-hover-bg);
  text-decoration: none;
  color: var(--white);
}

@media (max-width: 992px) {
  .fullWidthBtn {
    width: 100%;
    justify-content: center;
    background: white;
    color: var(--primary-btn-color);
    position: relative;
    z-index: 5;
    bottom: -10px;
  }
}

.twocolumnbulletedpoints {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  column-gap: 24px;
}
.twocolumnbulletedpoints li{
  flex-direction: column;
    row-gap: 15px;
    display: flex;
  align-items: flex-start;
    margin-bottom: 1.5rem;
    line-height: 120%;
  }
  .twocolumnbulletedpoints li .litextdiv{
    display: grid;
  grid-template-columns: 10px 1fr;
  column-gap: 10px;
  }
  .twocolumnbulletedpoints .bulletIcon {
    display: block;
    height: 6px;
    width: 6px;
    background: var(--headingColor);
    border-radius: 2px 0 2px 0;
    margin-top: 8px;
}
.twocolumnbulletedpoints .bulletIcon.circleBullet {
    height: 9px;
    width: 9px;
    background: transparent;
    border: 1.5px solid var(--headingColor);
    border-radius: 50%;
}
@media (max-width: 490px) {
  .twocolumnbulletedpoints {
    display: block;
  }
 .twocolumnbulletedpoints li{
margin-bottom:1.5rem
  }
  .twocolumnbulletedpoints .bulletIcon {
    margin-top: 4px;
}
}
  

[lang="ar"] .primaryBtn .chevronRight {
  transform: rotate(180deg);
}
