.companyCard {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  /* height: max-content; */
  transition: transform .5s ease;
  background: white;
}

.companyCard:hover{
  transform: translateY(-10px);
  box-shadow: 0px 0px 30px 0px #0000001A;
}

.companyCard .cardImg {
  aspect-ratio: 3/2;
}

.companyCard .cardImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  visibility: visible;
}
.companyCard .cardBody {
  padding: 1.5rem;
  background-color: var(--white);
  gap: 0.2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.companyCard:hover .cardBody{
  background-color: white;
}

.companyCard .cardBody > h5 {
  color: var(--headingColor);
  font-size: clamp(1.15rem, 4vw, 1.35rem) !important; 
  line-height: 120%;  
}
/* .companyCard .cardBody>h5.hasMinhight {
  min-height: 60px;
} */
.companyCard .cardBody > p {
  color: var(--text-primary);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.companyCard .cardBody > a {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.companyCard .cardBody > a > p {
  color: var(--primary-btn-color);
}

/* @media(max-width:1024px){
  .companyCard{
    min-width: 316px;
  }
} */

@media (min-width: 992px) {
  .cardBody {
    padding: 1.6rem;
  }
}

[lang="ar"] .cardBody > a > img {
  transform: rotate(180deg);
}
[lang="ar"]   .cardBody {
  text-align: right;}