
/* SomarSansRegular
SomarSansBold
SomarSansMedium
SomarSansMedium

SomarSansLight
TiemposTextRegular
TiemposTextMedium

LaureenZazaRegular
LaureenZazaMedium
LaureenZazaBold

*/
/* ---------------------------------------------------------------------Somar Sans 100 ------------------------------ */
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-Thin.eot');
    src: local('Somar Sans Thin'), local('SomarSans-Thin'),
        url('SomarSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-Thin.woff2') format('woff2'),
        url('SomarSans-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans 200 ------------------------------ */
@font-face {
    font-family: 'SomarSansExtraLight';
    src: url('SomarSans-ExtraLight.eot');
    src: local('Somar Sans ExtraLight'), local('SomarSans-ExtraLight'),
        url('SomarSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-ExtraLight.woff2') format('woff2'),
        url('SomarSans-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans 300 ------------------------------ */
@font-face {
    font-family: 'SomarSansLight';
    src: url('SomarSans-Light.eot');
    src: local('Somar Sans Light'), local('SomarSans-Light'),
        url('SomarSans-Light.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-Light.woff2') format('woff2'),
        url('SomarSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SomarSansRegular';
    src: url('SomarSans-SemiBold.eot');
    src: local('Somar Sans SemiBold'), local('SomarSans-SemiBold'),
        url('SomarSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-SemiBold.woff2') format('woff2'),
        url('SomarSans-SemiBold.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans Regular ------------------------------ */
@font-face {
    font-family: 'SomarSansRegular';
    src: url('SomarSans-Regular.eot');
    src: local('Somar Sans Regular'), local('SomarSans-Regular'),
        url('SomarSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-Regular.woff2') format('woff2'),
        url('SomarSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans 500 ------------------------------ */
@font-face {
    font-family: 'SomarSansMedium';
    src: url('SomarSans-Medium.eot');
    src: local('Somar Sans Medium'), local('SomarSans-Medium'),
        url('SomarSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-Medium.woff2') format('woff2'),
        url('SomarSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans 600 ------------------------------ */

@font-face {
    font-family: 'SomarSansBold';
    src: url('SomarSans-Bold.eot');
    src: local('Somar Sans Bold'), local('SomarSans-Bold'),
        url('SomarSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-Bold.woff2') format('woff2'),
        url('SomarSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* ---------------------------------------------------------------------Somar Sans 900 ------------------------------ */
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-Black.eot');
    src: local('Somar Sans Black'), local('SomarSans-Black'),
        url('SomarSans-Black.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-Black.woff2') format('woff2'),
        url('SomarSans-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-ExtraBold.eot');
    src: local('Somar Sans ExtraBold'), local('SomarSans-ExtraBold'),
        url('SomarSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-ExtraBold.woff2') format('woff2'),
        url('SomarSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans 100 Italic ------------------------------ */
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-ThinItalic.eot');
    src: local('Somar Sans Thin Italic'), local('SomarSans-ThinItalic'),
        url('SomarSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-ThinItalic.woff2') format('woff2'),
        url('SomarSans-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans 200 Italic ------------------------------ */
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-ExtraLightItalic.eot');
    src: local('Somar Sans ExtraLight Italic'), local('SomarSans-ExtraLightItalic'),
        url('SomarSans-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-ExtraLightItalic.woff2') format('woff2'),
        url('SomarSans-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans 300 Italic ------------------------------ */
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-LightItalic.eot');
    src: local('Somar Sans Light Italic'), local('SomarSans-LightItalic'),
        url('SomarSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-LightItalic.woff2') format('woff2'),
        url('SomarSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans Regular Italic ------------------------------ */
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-RegularItalic.eot');
    src: local('Somar Sans Regular Italic'), local('SomarSans-RegularItalic'),
        url('SomarSans-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-RegularItalic.woff2') format('woff2'),
        url('SomarSans-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans 500 Italic ------------------------------ */
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-MediumItalic.eot');
    src: local('Somar Sans Medium Italic'), local('SomarSans-MediumItalic'),
        url('SomarSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-MediumItalic.woff2') format('woff2'),
        url('SomarSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans 600 Italic ------------------------------ */
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-BoldItalic.eot');
    src: local('Somar Sans Bold Italic'), local('SomarSans-BoldItalic'),
        url('SomarSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-BoldItalic.woff2') format('woff2'),
        url('SomarSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-SemiBoldItalic.eot');
    src: local('Somar Sans SemiBold Italic'), local('SomarSans-SemiBoldItalic'),
        url('SomarSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-SemiBoldItalic.woff2') format('woff2'),
        url('SomarSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans 900 Italic ------------------------------ */

@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-BlackItalic.eot');
    src: local('Somar Sans Black Italic'), local('SomarSans-BlackItalic'),
        url('SomarSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-BlackItalic.woff2') format('woff2'),
        url('SomarSans-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}
/* ---------------------------------------------------------------------Somar Sans Bold Italic ------------------------------ */
@font-face {
    font-family: 'Somar Sans';
    src: url('SomarSans-ExtraBoldItalic.eot');
    src: local('Somar Sans ExtraBold Italic'), local('SomarSans-ExtraBoldItalic'),
        url('SomarSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('SomarSans-ExtraBoldItalic.woff2') format('woff2'),
        url('SomarSans-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}






@font-face {
    font-family: 'LaureenZazaMedium';
    src: url('LaureenZazaText-Medium.eot');
    src: local('Laureen Zaza Text Medium'), local('LaureenZazaText-Medium'),
        url('LaureenZazaText-Medium.eot?#iefix') format('embedded-opentype'),
        url('LaureenZazaText-Medium.woff2') format('woff2'),
        url('LaureenZazaText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'LaureenZazaBold';
    src: url('LaureenZazaDisplay-Bddisplay.eot');
    src: local('Laureen Zaza Display Bd display'), local('LaureenZazaDisplay-Bddisplay'),
        url('LaureenZazaDisplay-Bddisplay.eot?#iefix') format('embedded-opentype'),
        url('LaureenZazaDisplay-Bddisplay.woff2') format('woff2'),
        url('LaureenZazaDisplay-Bddisplay.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Laureen Zaza Display';
    src: url('LaureenZazaDisplay-Bddisplay.eot');
    src: local('Laureen Zaza Display Bd display'), local('LaureenZazaDisplay-Bddisplay'),
        url('LaureenZazaDisplay-Bddisplay.eot?#iefix') format('embedded-opentype'),
        url('LaureenZazaDisplay-Bddisplay.woff2') format('woff2'),
        url('LaureenZazaDisplay-Bddisplay.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Laureen Zaza Text';
    src: url('LaureenZazaText-Regular.eot');
    src: local('Laureen Zaza Text Regular'), local('LaureenZazaText-Regular'),
        url('LaureenZazaText-Regular.eot?#iefix') format('embedded-opentype'),
        url('LaureenZazaText-Regular.woff2') format('woff2'),
        url('LaureenZazaText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Laureen Zaza Text';
    src: url('LaureenZazaText-Regular.eot');
    src: local('Laureen Zaza Text Regular'), local('LaureenZazaText-Regular'),
        url('LaureenZazaText-Regular.eot?#iefix') format('embedded-opentype'),
        url('LaureenZazaText-Regular.woff2') format('woff2'),
        url('LaureenZazaText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Laureen Zaza Text';
    src: url('LaureenZazaText-Medium.eot');
    src: local('Laureen Zaza Text Medium'), local('LaureenZazaText-Medium'),
        url('LaureenZazaText-Medium.eot?#iefix') format('embedded-opentype'),
        url('LaureenZazaText-Medium.woff2') format('woff2'),
        url('LaureenZazaText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Laureen Zaza Text';
    src: url('LaureenZazaText-Bold.eot');
    src: local('Laureen Zaza Text Bold'), local('LaureenZazaText-Bold'),
        url('LaureenZazaText-Bold.eot?#iefix') format('embedded-opentype'),
        url('LaureenZazaText-Bold.woff2') format('woff2'),
        url('LaureenZazaText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}







@font-face {
    font-family: 'Tiempos Text';
    src: url('TiemposText-MediumItalic.eot');
    src: local('Tiempos Text Medium Italic'), local('TiemposText-MediumItalic'),
        url('TiemposText-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('TiemposText-MediumItalic.woff2') format('woff2'),
        url('TiemposText-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'TiemposTextRegular';
    src: url('TiemposText-RegularItalic.eot');
    src: local('Tiempos Text Regular Italic'), local('TiemposText-RegularItalic'),
        url('TiemposText-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('TiemposText-RegularItalic.woff2') format('woff2'),
        url('TiemposText-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

/* ---------------------------------------------------------------------Tiempos Text ------------------------------ */
@font-face {
    font-family: 'TiemposTextMedium';
    src: url('TiemposText-Medium.eot');
    src: local('Tiempos Text Medium'), local('TiemposText-Medium'),
        url('TiemposText-Medium.eot?#iefix') format('embedded-opentype'),
        url('TiemposText-Medium.woff2') format('woff2'),
        url('TiemposText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
/* ---------------------------------------------------------------------Tiempos Text Bold ------------------------------ */
@font-face {
    font-family: 'TiemposTextBold';
    src: url('TiemposText-Bold.eot');
    src: local('Tiempos Text Bold'), local('TiemposText-Bold'),
        url('TiemposText-Bold.eot?#iefix') format('embedded-opentype'),
        url('TiemposText-Bold.woff2') format('woff2'),
        url('TiemposText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
/* ---------------------------------------------------------------------Tiempos Text Bold Italic ------------------------------ */
@font-face {
    font-family: 'TiemposTextBoldItalic';
    src: url('TiemposText-BoldItalic.eot');
    src: local('Tiempos Text Bold Italic'), local('TiemposText-BoldItalic'),
        url('TiemposText-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('TiemposText-BoldItalic.woff2') format('woff2'),
        url('TiemposText-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

